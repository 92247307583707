import React, { useEffect } from 'react';

import { Loader } from '@shared/ui/loader/Loader';
import { LoaderWrapper } from '@shared/ui/loader/LoaderWrapper';

export const LegionPlaceholder = () => {
  /**
   * Just for social sharing
   */

  useEffect(() => {
    window.location.href = window.location.origin;
  }, []);

  return (
    <LoaderWrapper className="min-h-[200px]">
      <Loader />
    </LoaderWrapper>
  );
};
